import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';

const ImageBoxStyled = styled('div')`
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;

  .custombutton {
    margin-top: 40px;
    padding: 15px;
    font-size: 18px;
    background: ${props => props.buttoncolor}!important;
    color: ${props => props.buttontextcolor}!important;
  }

  img {
    object-fit: cover !important;
  }

  .herotext {
    i {
      font-style: normal;
      font-family: Heldane Italic;
    }
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${props => props.foregroundcolor}!important;
    padding: 30px;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 1px;
    overflow: hidden;
    text-align: center;
    margin-top: 10px;
  }

  ${theme.below.lg} {
    .custombutton {
      margin-top: 40px;
      padding: 15px;
      font-size: 12px;
      background: ${props => props.buttoncolor}!important;
      color: ${props => props.buttontextcolor}!important;
    }

    .herotext {
      width: 100%;

      i {
        font-size: 0.9rem;
        font-style: normal;
        font-family: Heldane Italic;
      }

      h2 {
        font-size: 1.2rem !important;
      }

      p {
        font-size: 1.2rem !important;
      }

      .underlined {
        margin-top: 0 !important;
        text-decoration: underline;
        font-size: 1.3rem !important;
      }
    }

    [data-flight-image-root] {
      transition: all 1.5s;
    }

    [data-flight-image-root] {
      &:hover {
        transform: scale(1.03);
      }
    }
  }
`;

const HeroLink = styled(Link)`
  width: 100%;
  position: relative;
  display: block;
`;

export const ImageBox = ({
  image,
  link,
  title,
  foregroundcolor = 'black',
  button,
  buttontext,
  buttoncolor,
  buttontextcolor
}) => {
  return (
    <>
      {link?.value ? (
        <HeroLink to={link?.value}>
          <ImageBoxStyled
            buttoncolor={buttoncolor?.value}
            buttontextcolor={buttontextcolor?.value}
            foregroundcolor={foregroundcolor?.value}
          >
            {title?.value && (
              <div className="herotext">
                <div dangerouslySetInnerHTML={{ __html: title?.value }} />
                {button?.value ? (
                  <div>
                    <button className="custombutton">
                      {buttontext?.value}
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
            <Above breakpoint="lg">
              {matches =>
                matches ? (
                  <Image
                    src={image.value}
                    aspect="1200:800"
                    crop={true}
                    quality={100}
                  />
                ) : (
                  <Image
                    src={image.value}
                    aspect="1:1"
                    crop={true}
                    quality={100}
                  />
                )
              }
            </Above>
          </ImageBoxStyled>
        </HeroLink>
      ) : (
        <ImageBoxStyled>
          <Image
            src={image.value}
            aspect="1200:800"
            crop={true}
            quality={100}
          />
        </ImageBoxStyled>
      )}
    </>
  );
};
