import React from 'react';
import { styled } from 'linaria/react';
import { useYotpoWidget } from './Yotpo';

const Wrapper = styled('div')`
  width: 100%;

  #carousel-top-panel .headline,
  .yotpo-label {
    display: none;
  }
`;

const ReviewsCarousel = ({ widget }) => {
  const loaded = useYotpoWidget();

  return (
    <>
      {loaded ? (
        <Wrapper>
          <div dangerouslySetInnerHTML={{ __html: widget }} />
        </Wrapper>
      ) : null}
    </>
  );
};

export default ReviewsCarousel;
