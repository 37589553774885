import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { CategoryHeaderWrapper } from './CategoryHeader';

export default React.memo(function CategoryHeaderLoadingState() {
  const backgroundColor = '#e2e2e2';
  return (
    <CategoryHeaderWrapper>
      <MaxWidth>
        <LoadingLine
          color={backgroundColor}
          heightPx={14}
          style={{
            marginBottom: '2rem',
            marginTop: '2rem',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        />
        <LoadingLine
          color={backgroundColor}
          widthRem={10}
          heightPx={27}
          style={{
            marginBottom: '20px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        />
        <LoadingLine
          color={backgroundColor}
          widthRem={25}
          randomizeWidthBy={5}
          count={2}
          heightPx={14}
          style={{
            marginBottom: '0',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        />
      </MaxWidth>
    </CategoryHeaderWrapper>
  );
});
