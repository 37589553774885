import React from 'react';
import ReviewsCarousel from '../../Yotpo/ReviewsCarousel';

const Reviews = props => {
  const widget = props?.widget?.value;

  if (!widget) return null;

  return <ReviewsCarousel widget={widget} />;
};

export default Reviews;
