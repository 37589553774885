import { css, cx } from 'linaria';
import React from 'react';
import GridProduct from '../../CategoryPage/GridProduct';

const sliderCardStyle = css`
  width: 100% !important;
`;

export function SliderProduct({ product, aspect, ...rest }) {
  return product || product?.value ? (
    <GridProduct
      className={cx(sliderCardStyle, 'slider-product')}
      as="div"
      product={product?.value ?? product}
      imageAspect={aspect}
      {...rest}
    />
  ) : null;
}
