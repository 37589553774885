import { Below } from '@jetshop/ui/Breakpoints';
import Carousel from 'my-react-carousel';
import React, { useRef } from 'react';
import {
  CarouselContainer,
  CarouselHeader,
  numberOfSlides,
  toNum
} from './CarouselHeader';
import { SliderProduct } from './SliderProduct';

function useChild(children) {
  if (!children) {
    return null;
  }

  const [child] = children;

  return child.props.category.value;
}

function SliderContent({
  children,
  title,
  slidesToShow,
  aspect,
  peakOffset,
  ...props
}) {
  const ref = useRef();
  const category = useChild(children);

  return (
    <>
      <Carousel
        ref={ref}
        slidesToShow={slidesToShow}
        infinite={false}
        render={({ slides, ...rest }) => {
          return (
            <CarouselContainer>
              <CarouselHeader
                carousel={ref}
                title={title}
                slidesToShow={slidesToShow}
                peakOffset={peakOffset}
                category={category}
                {...rest}
                {...props}
              />
              {slides}
            </CarouselContainer>
          );
        }}
      >
        {category?.products?.result?.map(product => (
          <div key={product.id}>
            <SliderProduct product={product} aspect={aspect?.value} />
          </div>
        )) ?? null}
      </Carousel>
    </>
  );
}

export function CategoryProductSlider(props) {
  const [mdDownSlides, mdUpSlides] = toNum(
    props?.mdDownSlides,
    props?.mdUpSlides
  );

  return (
    <Below breakpoint="md">
      {matches =>
        matches ? (
          <SliderContent
            slidesToShow={numberOfSlides(mdDownSlides, 2.2)}
            peakOffset={0.2}
            {...props}
          />
        ) : (
          <SliderContent
            slidesToShow={numberOfSlides(mdUpSlides, 4.5)}
            peakOffset={0.5}
            {...props}
          />
        )
      }
    </Below>
  );
}
