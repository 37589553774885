import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { Title } from '../StartPage';

import Image from '@jetshop/ui/Image';

const HeroTextBlockContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 60px 40px;

  &.with-image {
    padding: 0;
    .left {
      display: flex;
      align-items: center;
    }
  }

  .left {
    flex: 1;
    h2 {
      font-size: 2em;
      font-weight: 200;
      i {
        font-style: italic;
        font-family: Heldane Italic;
      }
    }
  }
  .right {
    flex: 1;
    p {
      padding-right: 90px;
      padding-left: 50px;
      font-size: 1.3em;
      font-weight: 200;
    }
  }
  ${theme.below.lg} {
    margin-bottom: 0px !important;
    padding: 30px 20px !important;
    h2 {
      font-size: 1.3rem !important;
    }
    p {
      line-height: 1.5em !important;
      padding: 0 !important;
      font-size: 0.8rem !important;
    }
  }
`;

const ImageContainer = styled.div`
  padding: 1rem;
`;

export const HeroTextBlock = ({ title, leftText, rightText, image }) => {
  const hasImage = image?.value;

  return (
    <>
      <Title>{title.value}</Title>
      <HeroTextBlockContainer className={cx(hasImage && 'with-image')}>
        <div className="left">
          <div dangerouslySetInnerHTML={{ __html: leftText.value }} />
        </div>
        <div className="right">
          {hasImage ? (
            <ImageContainer>
              <Image aspect="16:9" cover src={image?.value} />
            </ImageContainer>
          ) : (
            <p>{rightText?.value}</p>
          )}
        </div>
      </HeroTextBlockContainer>
    </>
  );
};
