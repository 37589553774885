import Head from '@jetshop/core/components/Head';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import React from 'react';
import { useQuery } from 'react-apollo';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';

import startPageQuery from './StartPageQuery.gql';

import { CategoryImage, CategoryImages } from './Content/CategoryImages';
import { CategoryProductSlider } from './Content/CategoryProductSlider';
import { Column, Columns } from './Content/Columns';
import { HeroTextBlock } from './Content/HeroTextBlock';
import { ImageBox } from './Content/ImageBox';
import { StartpageProductGrid } from './Content/StartpageProductGrid';
import { Video } from './Content/Video';
import YotpoUGC from '../Yotpo/YotpoUGC';
import Reviews from './Content/Reviews';

export const MainSectionWrapper = styled(MaxWidth)`
  align-self: center;
  padding: 10px 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem auto;
  margin-top: 0;
  max-width: 82.5rem;

  &.fullWidth {
    max-width: 112.5rem;
    padding: 0 0.5rem;
  }
`;

export const Title = styled('h4')`
  width: 100%;
  color: black;

  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 1px;
  overflow: hidden;
  padding: 30px 0;
  padding-top: 0;
  text-align: center;
  text-transform: uppercase;
  &:before,
  &:after {
    background: #eaeaea;
    content: '';
    display: inline-block;
    height: 1px;
    margin-top: -3px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &:before {
    right: 0.5em;
    margin-left: -50%;
  }
  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
`;

const StartPageTitle = ({ title }) =>
  title?.value ? <Title>{title.value}</Title> : null;

const startPageComponents = {
  IMAGEBOX: props => (
    <MainSectionWrapper className={cx(props?.fullWidth?.value && 'fullWidth')}>
      <ImageBox {...props} />
    </MainSectionWrapper>
  ),
  CATEGORYIMAGES: props => (
    <MainSectionWrapper>
      <CategoryImages {...props} />
    </MainSectionWrapper>
  ),
  CATEGORYIMAGE: CategoryImage,
  TITLE: props => (
    <MainSectionWrapper>
      <StartPageTitle {...props} />
    </MainSectionWrapper>
  ),
  'CATEGORY PRODUCT SLIDER': props => (
    <MainSectionWrapper>
      <CategoryProductSlider {...props} />
    </MainSectionWrapper>
  ),
  SLIDERCATEGORYPRODUCTS: props => props,
  PRODUCTGRID: props => (
    <MainSectionWrapper>
      <StartpageProductGrid {...props} />
    </MainSectionWrapper>
  ),
  PRODUCT: props => props,
  HEROTEXTBLOCK: props => (
    <MainSectionWrapper>
      <HeroTextBlock {...props} />
    </MainSectionWrapper>
  ),
  VIDEO: props => (
    <MainSectionWrapper>
      <Video {...props} />
    </MainSectionWrapper>
  ),
  COLUMNS: props => (
    <MainSectionWrapper className="fullWidth">
      <Columns {...props} />
    </MainSectionWrapper>
  ),
  COLUMN: Column,
  REVIEWS: props => (
    <MainSectionWrapper>
      <Reviews {...props} />
    </MainSectionWrapper>
  )
};

export const ContentRender = ({ items = [] }) => {
  return (
    <ContentRenderer items={items ?? []} components={startPageComponents} />
  );
};

const StartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId }
  });

  const { loading } = result;

  if (loading || !result?.data?.startPage) return <LoadingPage />;

  return (
    <>
      <Head data={result?.data?.startPage} />
      <ContentRender items={result?.data?.startPage?.data?.items} />
      <YotpoUGC galleryId="635797e7d14d0930c6ac6652" />
    </>
  );
};

export default StartPage;
