import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';

const Grid = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: ${p => `repeat(${p.columns}, 1fr)`};
  ${theme.below.md} {
    grid-template-columns: 1fr;
  }

  .column {
    > a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }

    img {
      transition: transform 4s cubic-bezier(0.215, 0.61, 0.355, 1),
        opacity 500ms ease 0s !important;

      &:hover {
        ${theme.above.md} {
          transform: scale(1.1);
        }
      }
    }
  }
`;

const Title = styled.h2`
  text-decoration: none;
  color: ${({ color = 'white' }) => color};
  position: absolute;
  z-index: 12;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  text-align: center;
  pointer-events: none;
`;

export function Column({ image, title, link, titleColor }) {
  return (
    <div className="column">
      <Link to={link.value}>
        {title?.value && <Title color={titleColor?.value}>{title.value}</Title>}
        <Image src={image.value} cover />
      </Link>
    </div>
  );
}

export function Columns({ children }) {
  return (
    <Grid className="grid" columns={children?.length}>
      {React.Children.map(children, ({ props }) => (
        <Column {...props} />
      ))}
    </Grid>
  );
}
