import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/Image';

import { theme } from '../Theme';

import { ContentRender } from '../StartPage/StartPage';

export const CategoryHeaderWrapper = styled(MaxWidth)`
  display: flex;
  flex-direction: ${props => (props.hasProducts ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${props => (props.hasProducts ? 'flex-end' : 'center')};
  width: 100%;
  padding-bottom: 5px;
  border-bottom: ${props => (props.hasProducts ? '1px solid #eaeaea' : 'none')};
  margin-bottom: 1rem;
  margin-top: 1rem;

  ${theme.below.lg} {
    padding: 0rem 0 0.5rem 0 !important;
  }

  ${theme.below.sm} {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
`;

const CategoryImageHeaderContainer = styled(MaxWidth)`
  width: inherit;
`;

const CategoryNoImageHeader = styled('div')`
  display: none;
`;

const CategoryName = styled('h1')`
  color: black;
  display: block;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: normal;
  text-transform: none;
  ${theme.below.md} {
    margin-bottom: 10px;
  }
`;

const Subcategories = styled('div')`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding: 0 10px 20px 10px;

  &[data-has-subcategories='false'] {
    display: none;
  }

  ${theme.below.md} {
    justify-content: flex-start;
    padding: 0;
    padding-left: 10px;
    overflow: scroll;

    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      display: none;
    }
    ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }

  a {
    display: block;
    text-decoration: none;
    background: white;
    color: black;
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-weight: normal;
    border: 1px solid black;

    ${theme.below.md} {
      flex: none;
      margin: 0 5px 0 0;
      font-size: 12px;
    }

    &.active {
      background: black;
      color: white;
    }
  }
`;

const CategoryContent = styled('div')`
  display: block;
  font-weight: 300;
  line-height: 26px;
  font-size: 18px;
  width: 100%;

  h2 {
    color: black;

    font-size: 1.5rem;
    font-weight: 300;
    margin: auto;
    max-width: ${props => (props.hasProducts ? '65%' : '100%')};
    padding: ${props => (props.hasProducts ? '0 0 10px 0' : '0 .5rem')};
  }

  p {
    color: #888;

    font-size: 16px;
    font-weight: 300;
    margin: auto;
    max-width: ${props => (props.hasProducts ? '65%' : '100%')};
    padding: ${props => (props.hasProducts ? '0' : '0 .5rem')};
    a {
      color: #eaeaea;
      text-decoration: none;
      transition: all 0.3s;
      :hover {
        color: #888;
      }
    }
  }

  img {
    max-width: 100%;
  }

  iframe {
    margin: 0.5rem 0rem;
    width: 100%;
  }

  .imageWrapper {
    position: relative;
    img {
      width: 100%;
      height: 50vw;
      object-fit: cover;
    }
    p {
      padding: 2rem !important;
    }
    h2 {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 1.8rem !important;
      transform: translate(-50%, -50%);
      text-align: left;
      i {
        font-family: Heldane Italic;
        font-style: italic;
      }
      em {
        font-family: Heldane Italic;
        font-style: italic;
      }
    }
    ${theme.below.lg} {
      h2 {
        line-height: 1em !important;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 0.8rem !important;
        transform: translate(-50%, -50%);
        text-align: left;
        em {
          font-family: Heldane Italic;
          font-style: italic;
        }
        i {
          font-family: Heldane Italic;
          font-style: italic;
        }
      }
    }
  }
  .reverse {
    flex-direction: row-reverse;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    p {
      flex: 1;
      width: 50%;
      display: flex;
      align-items: center;
      font-size: medium;
      color: black;
      padding: 0 4rem;
    }
    ${theme.below.lg} {
      img {
        width: 100%;
        height: 100%;
        object-position: center center;
        object-fit: cover;
      }
      p {
        line-height: 1.3 !important;
        padding: 1rem !important;
        max-width: 50%;
        width: 100%;
        font-size: small;
        min-height: 43vw;
      }
    }
  }

  .container {
    position: relative;
    width: 50%;
    flex: none;

    img {
      object-fit: cover;
      height: 100%;
    }

    h2 {
      position: absolute;
      color: white;
      top: 50%;
      left: 50%;
      font-size: 1.8rem !important;
      transform: translate(-50%, -50%);
      text-align: left;
      em {
        font-family: Heldane Italic;
        font-style: italic;
      }
      i {
        font-family: Heldane Italic;
        font-style: italic;
      }
    }
    h2 {
      line-height: 1em !important;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 1.8rem !important;
      transform: translate(-50%, -50%);
      text-align: left;
      em {
        font-family: Heldane Italic;
        font-style: italic;
      }
      i {
        font-family: Heldane Italic;
        font-style: italic;
      }
    }
    ${theme.below.lg} {
      h2 {
        padding: 0;
        line-height: 1em !important;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 0.8rem !important;
        transform: translate(-50%, -50%);
        text-align: left;
        em {
          font-family: Heldane Italic;
          font-style: italic;
        }
        i {
          font-family: Heldane Italic;
          font-style: italic;
        }
      }
    }
  }
`;

const CatContent = styled('div')`
  max-width: 1160px;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  padding-top: 0;
  ${theme.below.md} {
    font-size: 14px;
  }
`;

const Container = styled('div')`
  margin-top: 1rem;
  ${theme.below.lg} {
    margin-top: 1.5rem;
  }
`;

const CategoryHeader = ({ category, hasProducts }) => {
  const hasContentItems = category?.data?.items?.length > 0;
  const hasLegacyCategoryImage = category.images && category.images.length > 0;
  return (
    <Container>
      {hasContentItems ? (
        <ContentRender items={category?.data?.items} />
      ) : hasLegacyCategoryImage ? (
        <React.Fragment>
          <CategoryImageHeaderContainer>
            <Image
              aspect={'16:9'}
              critical
              quality={100}
              sizes={[1 / 2]}
              src={category.images}
              alt={category.name}
            />
          </CategoryImageHeaderContainer>
        </React.Fragment>
      ) : (
        <CategoryNoImageHeader />
      )}

      {hasProducts && category.content.length > 0 && !hasContentItems && (
        <div hasProducts={hasProducts}>
          <>
            <CategoryName data-testid="page-header">
              {category.mainHeader}
            </CategoryName>
            <CatContent
              dangerouslySetInnerHTML={{ __html: category.content }}
            />
          </>
        </div>
      )}

      <CategoryHeaderWrapper hasProducts={hasProducts}>
        <Subcategories
          data-has-subcategories={
            category.subcategories.length > 0 ||
            category?.parent?.subcategories?.length > 0
          }
        >
          {category.subcategories && category.subcategories.length > 0
            ? category.subcategories.map(subcat => (
                <Link
                  className={
                    subcat.name === category.name ? 'active' : 'not-active'
                  }
                  key={subcat.name}
                  to={subcat.primaryRoute.canonicalPath}
                >
                  {subcat.name}
                </Link>
              ))
            : category.parent &&
              category.parent.subcategories.map(subcat => (
                <Link
                  className={
                    subcat.name === category.name ? 'active' : 'not-active'
                  }
                  key={subcat.name}
                  to={subcat.primaryRoute.canonicalPath}
                >
                  {subcat.name}
                </Link>
              ))}
        </Subcategories>
        {!hasProducts && !hasContentItems && (
          <CategoryContent
            data-testid="category-description"
            dangerouslySetInnerHTML={{
              __html: category.content
            }}
          />
        )}
      </CategoryHeaderWrapper>
    </Container>
  );
};

export default CategoryHeader;
