import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';

const EmbedContainer = styled.section`
  position: relative;
  width: 100%;

  .video-wrapper {
    padding-bottom: ${({ aspect }) => aspect};
  }

  video,
  iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: ${({ alignTitle = 'center' }) => alignTitle};
  h2 {
    color: ${({ titleColor = 'white' }) => titleColor};
    font-size: 2.25rem;
    ${theme.below.lg} {
      font-size: 1.65rem;
    }
    ${theme.below.md} {
      font-size: 1.25rem;
    }
  }
`;

function getAspectRatio(aspect) {
  switch (aspect) {
    case '16/9':
      return '56.25%';
    case '4/3':
      return '75%';
    case '3/4':
      return '133.33%';
    default:
      return '56.25%';
  }
}

const base = {
  Youtube: ({ id, autoplay, controls, loop = false }) => {
    const params = {
      mute: autoplay ? 1 : 0,
      autoplay: autoplay ? 1 : 0,
      loop: loop ? 1 : 0,
      controls: controls ? 1 : 0,
      playsinline: 1
    };

    return `https://www.youtube.com/embed/${id}?${qs.stringify(params)}`;
  },
  Vimeo: ({ id, autoplay, controls }) => {
    const params = {
      muted: autoplay ? 1 : 0,
      autoplay: autoplay ? 1 : 0,
      controls: controls ? 1 : 0,
      playsinline: 1
    };

    return `https://player.vimeo.com/video/${id}?${qs.stringify(params)}`;
  }
};

export const Video = props => {
  const type = props.videoType.value;
  const aspect = getAspectRatio(props.aspect?.value);
  const link = props?.link?.value;
  const title = props?.title?.value;

  return (
    <EmbedContainer aspect={aspect}>
      {link ? (
        <div className="video-wrapper">
          <Link to={link}>
            <TextContainer
              titleColor={props?.titleColor?.value}
              alignTitle={props?.alignTitle?.value}
            >
              <h2>{title}</h2>
            </TextContainer>
            {type === 'Internal' ? (
              <InternalVideo {...props} />
            ) : (
              <ExternalVideo {...props} />
            )}
          </Link>
        </div>
      ) : (
        <div className="video-wrapper">
          <TextContainer
            titleColor={props?.titleColor?.value}
            alignTitle={props?.alignTitle?.value}
          >
            <h2>{title}</h2>
          </TextContainer>
          {type === 'Internal' ? (
            <InternalVideo {...props} />
          ) : (
            <ExternalVideo {...props} />
          )}
        </div>
      )}
    </EmbedContainer>
  );
};

export const InternalVideo = ({
  videoId,
  autoplay,
  controls,
  preloadimage
}) => {
  const { selectedChannel } = useContext(ChannelContext);
  // Apparently, for certain attributes, React is intelligent enough to omit the attribute if the value you pass to it is not truthy
  return (
    <video
      width="100%"
      muted={autoplay.value}
      autoPlay={autoplay.value}
      controls={controls.value}
      loop
      playsInline
      poster={preloadimage.value}
    >
      <source
        src={`${selectedChannel.url}/pub_docs/files/video/${videoId.value}`}
        type="video/mp4"
      />
    </video>
  );
};

export const ExternalVideo = ({ videoType, videoId, controls, autoplay }) => {
  const type = videoType.value;

  const src = base?.[type]({
    id: videoId.value,
    autoplay: autoplay.value,
    controls: controls.value
  });

  return (
    <iframe
      src={src}
      frameBorder="0"
      title="Embedded video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop;"
      allowFullScreen
    ></iframe>
  );
};
