import React from 'react';
import { styled } from 'linaria/react';
import CategoryHeader from '../CategoryPage/CategoryHeader';
import MaxWidth from '../Layout/MaxWidth';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import ProductGrid from './ProductGrid';
import get from 'lodash.get';
import GridProduct from './GridProduct';

import { theme } from '../Theme';
import YotpoUGC from '../Yotpo/YotpoUGC';

const Container = styled(MaxWidth)`
  flex-wrap: wrap;
  > div {
    width: 100%;
  }
  ${theme.above.sm} {
  }
  ${theme.below.lg} {
    overflow: hidden;
    padding: 0 !important;
  }
`;

const CategoryWrapper = styled('div')`
  background-color: #fff;
`;

const Category = ({ category, result: { loading, data } }) => {
  if (!category) {
    return <CategoryHeaderLoadingState />;
  }
  console.log(category);

  return (
    <>
      <CategoryWrapper>
        <CategoryHeader
          category={category}
          parents={get(data, 'route.parents')}
          hasProducts={category.products.totalResults > 0}
        />

        <Container>
          <ProductGrid
            products={category.products.result}
            listName={category.name}
            categoryPath={category.isDynamic ? null : data && data.route}
            loading={loading}
            ProductComponent={GridProduct}
          />
        </Container>
      </CategoryWrapper>
      {category?.products?.totalResults > 0 && (
        <YotpoUGC galleryId="6357ad6870f7957727abe87c" />
      )}
    </>
  );
};

export default Category;
