import CategoryLink from '@jetshop/ui/CategoryLink';
import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../Theme';

const CategoryImgStyle = styled('div')`
  display: inline-block;
  overflow: hidden;
  background: #e5e5e5;
  text-align: left;
  width: calc(${props => 100 / props.columns}% - 10px);
  [data-flight-image-root] {
    transition: all 1s;
  }
  [data-flight-image-root]:hover {
    transform: scale(1.03);
  }
  ${theme.below.md} {
    margin-bottom: 0;
    width: calc(${props => 100 / props.columns}% - 5px);
  }
`;

export const CategoryImage = ({ image, category, aspect = '600:313' }) => {
  return (
    <CategoryLink category={category?.value}>
      <Image src={image?.value} sizes={[1 / 2]} aspect={aspect} quality={100} />
    </CategoryLink>
  );
};

export const CategoryImages = ({ children }) => {
  return React.Children.map(children, ({ props }) => (
    <CategoryImgStyle columns={children.length}>
      <CategoryImage {...props} />
    </CategoryImgStyle>
  ));
};
