import ProductGrid from '@jetshop/ui/ProductList/ProductGrid';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const StyledProductGrid = styled(ProductGrid)`
  /* Override base UI components in ProductGrid */

  color: black;
  text-align: center;

  ${theme.below.lg} {
    margin: auto;
  }

  /* "delivered in packs of 3" badge text */
  span {
    display: block;
  }
  width: 100%;
  .new-price,
  .old-price {
    font-weight: 700;
    line-height: 1.5em;
  }

  .new-price {
    color: #c60f12;
  }
  .price {
    color: black;
  }
  .old-price {
    color: #999;
    display: block;
    font-size: 14px;
    font-weight: 300;
  }
`;

export default StyledProductGrid;
