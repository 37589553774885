import React from 'react';
import { Title } from '../StartPage';
import ProductsCarousel from '../../ProductsCarousel';
import { Above } from '@jetshop/ui/Breakpoints';
import ProductSlider from '../../ProductSlider';

export function StartpageProductGrid(props) {
  if (!props) {
    return null;
  }
  return (
    <>
      {props?.header?.value && <Title>{props.header.value}</Title>}
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <ProductsCarousel
              productsToShow={4}
              products={
                props?.children?.map(c => c?.props?.product?.value) ?? []
              }
            />
          ) : (
            <ProductSlider
              products={
                props?.children?.map(c => c?.props?.product?.value) ?? []
              }
            />
          )
        }
      </Above>
    </>
  );
}
