import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { ReactComponent as Chevron } from '../../../svg/Chevron.svg';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { Title } from '../StartPage';

export const CarouselContainer = styled.section`
  width: 100%;
  margin: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 0.5rem;
  padding-top: 0;
  margin-bottom: 0.65rem;

  h4 {
    font-family: ${({ fontFamily }) => theme.fonts[fontFamily]};
    color: ${({ titleColor }) => titleColor};
    font-weight: 200;
    font-size: 1.5rem;
    text-decoration: none;

    a {
      font-family: ${({ fontFamily }) => theme.fonts[fontFamily]};
      color: ${({ titleColor }) => titleColor};
      text-decoration: none;
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;

    button {
      background: transparent;

      &[data-direction='next'] {
        transform: rotate(-90deg);
      }

      &[data-direction='previous'] {
        transform: rotate(90deg);
      }

      &:disabled {
        opacity: 0.3;
        cursor: pointer;
      }

      svg {
        width: 30px;
        height: 30px;
        stroke: black;
      }
    }
  }
`;

export const numberOfSlides = (number, fallback) =>
  isNaN(number) ? fallback : number;

export const toNum = (mdUp, mdDown) => [mdUp?.value, mdDown?.value].map(Number);

export function CarouselHeader({
  carousel,
  slidesToShow,
  title,
  hasNext,
  hasPrevious,
  currentStep,
  totalSteps,
  // A pretty arbitrary number in order to get the offset right when using decimals in slidesToShow
  peakOffset = 1.2,
  fontFamily,
  titleColor,
  category = {},
  clickableTitle = { value: false }
}) {
  const headingProps = {
    fontFamily: fontFamily?.value ?? 'secondary',
    titleColor: titleColor?.value ?? 'black'
  };

  const truncSlidesToShow = Math.floor(slidesToShow);
  const truncTotalSteps = Math.floor(totalSteps);
  const truncCurrentStep = Math.floor(currentStep);

  const stepsLeft = truncTotalSteps - truncCurrentStep;

  const goToStepNext =
    stepsLeft <= truncSlidesToShow
      ? truncCurrentStep + (stepsLeft - peakOffset)
      : truncCurrentStep + truncSlidesToShow;

  const goToStepPrev =
    truncCurrentStep === 0
      ? truncCurrentStep
      : truncCurrentStep - truncSlidesToShow;

  return (
    <Header {...headingProps}>
      {(title?.value || (title && typeof title === 'string')) && (
        <>
          {category?.primaryRoute && clickableTitle?.value ? (
            <Title style={{ padding: 0 }}>
              <CategoryLink category={category}>
                {title?.value || title}
              </CategoryLink>
            </Title>
          ) : (
            <h2>{title?.value || title}</h2>
          )}
        </>
      )}
      <div className="button-wrapper">
        <button
          data-direction="previous"
          onClick={() => {
            carousel.current.goToStep(goToStepPrev);
          }}
          disabled={!hasPrevious}
        >
          <Chevron />
        </button>
        <button
          data-direction="next"
          onClick={() => {
            carousel.current.goToStep(goToStepNext);
          }}
          disabled={!hasNext}
        >
          <Chevron />
        </button>
      </div>
    </Header>
  );
}
